.dashboard-container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    top: 130px;
}






.button-upload-image {
    /* width: 100%; */
    border: solid;
    border-width: 1px;
    border-color: white;
    color: white;
    background-color: rgb(65, 65, 65);
    font-weight: 500;
    border-radius: 1.5rem/* 24px */;
    padding-left: 1.25rem/* 20px */;
    padding-right: 1.25rem/* 20px */;
    padding-top: 0.625rem/* 10px */;
    padding-bottom: 0.625rem/* 10px */;
    text-align: center;
    margin-bottom: 15px;
}
.img-preview {
    height: 160px;
    width: 90px;
    object-fit: cover;
}
.anime-listed {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.anime-img-listed-container {
    height: 40px;
    /* width: 50px; */
}
.anime-img-listed {
    height: 40px;
    width: 40px;
    border-radius: 100px;
    object-fit:cover;
}
.anime-title-listed {
    color: white;
    padding-left: 20px;
}
.delete-btn {
    background-color: rgb(65, 65, 65);
    color: white;
    border: solid;
    border-width: 1px;
    border-color: white;
    border-radius: 20px;
    margin-right: 5px;
    padding-left: 13px ;
    padding-right: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
}
.delete-btn:hover {
    background-color: rgb(143, 69, 69);
}
.anime-infos-listed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px;
}