/* body {
    background-color: rgb(23 23 23);
} */

.update-profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.update-profile-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content:center;
    align-items: start;
}
.update-profile-card {
    padding: 1rem/* 16px */;
    border-radius: 1.5rem/* 24px */;
    border-width: 1px;
    border-color: rgb(64 64 64);
    background-color: rgb(38 38 38);
}