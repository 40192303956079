.searchbar-section-container {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 295px;
    padding-top: 80px;
    padding-left: 26px;
    border-left: 1px solid rgb(83, 83, 83);
    background-color: rgb(0, 0, 0);
    overflow-y: auto;
}

.searchbar-section {
    /* margin-top: 20px; */
    position: relative;
    display: flex;
    align-items: center;
    z-index: 20;
}

.search-bar {
    /* position: fixed; */
    padding-left: 37px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    color: rgb(116, 116, 116);
    height: 38px;
    /* display: block; */
    background-color: rgb(36, 36, 36);
    border-radius: 20px;
}
.search-bar::placeholder {
    color: rgb(99, 99, 99);
    font-size: 12px
}
.search-bar-fixing {
    position: absolute;
    top: 20px;
    display: flex;
    align-items: center;
    width: 100%;

}

.search-icon {
    position: absolute;
    top: 10px;
    left: 30px;
    width: 20px;
    height: 20px;
    filter: invert(35%);
    z-index: 21;
}
.menu-points {
    width: 12px;
    height: 12px;
    filter: invert(35%);
    margin-top: 6px;
    
}
.title-image-container {
    display: flex;
    
    justify-content: space-between;
    margin-top: 20px;
    /* margin-right: 30px; */
}
.searchbar-title {
    font-weight: bold;
    margin-bottom: 13px;
}

.seemore-btn {
    background-color: rgb(36, 36, 36);
    color: rgb(99, 99, 99);
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    font-size: 14px;

}