.topRated-container {
    margin-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    /* display: flex;
    flex-wrap: wrap; */
    /* justify-content: space-between; */
}
.tra-movie-card {
    flex-wrap: wrap;
    display: flex;
    gap: 20px;
    justify-content: space-evenly;
    /* align-items: start; */
    
    
}
.tra-movie-item {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
    /* margin-bottom: 20px; */
    /* justify-content: center; */
    /* background-color: lightblue; */
    /* align-items: start; */
    /* width: 45%; */
    /* flex: 1 0 35%; */
    /* height: 135px; */
    
   
}
.tra-pic-container {
    /* margin-right: 15px; */
}
.tra-movie-picture {
    width: auto;
    height: auto;
    border-radius: 10%;
    object-fit:cover;
}
.tra-movie-infos {
    width: 100%;
    /* height: 135px; */
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.tra-movie-name {
    /* width: 150px; */
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
}

.tra-saisonsepisodes {
    font-size: 13px;
    color: gray;
}



.tra-title {
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 20px;
}

.tra-score {
    position: absolute;
    top: 10px;
    left: 10px;
}
@media screen and (min-width: 1300px) {
    .tra-movie-item {
        flex-basis: 14%;
    }
    .tra-movie-card {
        justify-content: space-between;
        gap: 15px;
    }
}