.header {
  position: absolute;
  z-index: 10;
  width: 100vw;
}

.header-container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
}

@media (max-width: 500px) {
  .header-container-second {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 110px;
  }
  
}

@media (min-width: 501px) {
  .header-container-second {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 144px;
  }
  
}

@media (min-width: 640px) {
  .header-container {
    padding-left: 1.5rem /* 24px */;
    padding-right: 1.5rem /* 24px */;
  }
}

@media (min-width: 1024px) {
  .header-container {
    padding-left: 2rem /* 32px */;
    padding-right: 2rem /* 32px */;
  }
}

/* .header-container-second {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 9rem /* 144px 
} */

.header-container-third {
  display: flex;
  align-items: center;
}

.header-logo-picture {
  flex-shrink: 0;
  z-index: 100;
}

.header-container-fourth {
  display: none;
  margin-left: 2.5rem /* 40px */;
}

@media (min-width: 768px) {
  .header-container-fourth {
    display: block;
  }
}

.header-container-fifth {
  margin-left: 96px;
  display: flex;
  align-items: baseline;
}

.header-buttons {
  color: white;
  padding-left: 0.75rem /* 12px */;
  padding-right: 0.75rem /* 12px */;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
  border-radius: 0.375rem /* 6px */;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  font-weight: 500;
}

.header-buttons:hover {
  background-color: rgb(55 65 81);
}

.header-hamburger-button-container {
  display: flex;
  position: absolute;
  right: 0;
  margin-right: 25px;
  z-index: 100;
}

@media (min-width: 768px) {
  .header-hamburger-button-container {
    display: none;
  }
}

.header-hamburger-button {
  background-color: rgb(25, 24, 53);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem /* 8px */;
  border-radius: 0.375rem /* 6px */;
  color: rgb(255, 255, 255);
}
.header-hamburger-button:hover {
  color: white;
  background-color: rgb(31 41 55 1);
}

.header-hamburger-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.svg-icon {
    display: block;
    height: 1.5rem/* 24px */;
    width: 1.5rem/* 24px */;
}

.logo-picture {
    height: 82px;
    width: 120px;
}

.menuopen {
    color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(27, 27, 27, 0.863);
    z-index: 5;
    transition: 0.3s;
    
}

.stop-scrolling {
  /* Class who's added to body when menu is opened */
  overflow: hidden;
}
