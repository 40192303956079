/* body {
    height: 200vh;
} */

.page-container-ap {
    /* padding-top: 144px; */
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
}
@keyframes zoomOutAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1) translateZ(-100px);
  }
}
.fondanime {
    opacity: 0.21;
    z-index: 0;
    height: 100vh;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    left: 0;
    object-fit: cover;
    animation: zoomOutAnimation 2s ease-out;
    animation-fill-mode: forwards;
}

.anime-affiche-container {
    /* display: flex; */
    /* justify-content: center; */
    /* height: 100vh;
    align-items: center; */
    z-index: 10;
}
.anime-affiche {
    height: 700px;
    width: 470px;
    border-radius: 30px;
    z-index: 10;
}

.carre1 {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 400px;
    width: 500px;
    background-color: rgb(46, 46, 46);
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.anime-title-ap {
    color: white;
    font-weight: bold;
    font-size: 35px;
}


.carre2 {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 400px;
    width: 500px;
    background-color: rgb(46, 46, 46);
    z-index: 10;
    padding: 10px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.message-input-container {
    /* background-color: lightblue; */
  position: absolute;
  bottom: 10px;
  display: flex;
  width: 100%;
}
.message-input {
    padding: 12px;
    width: 420px;
    background: rgba(116, 116, 116, 0.479);
    border-radius: 10px;
    margin-right: 10px;
}
.message-list {
    padding-left: 10px;
    position: absolute;
    bottom: 63px;
    height: 320px;
    width: 450px;
    /* background-color: lightblue; */
    overflow-x: scroll;
}

.msg-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.pdp-msg {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin-right: 8px;
}
.nom-msg-container {
    display: flex;
    flex-direction: column;
    line-height: 18px;
}
.pseudo {
    margin-bottom: 0px;
}