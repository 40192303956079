.navbar-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 230px;
    padding-top: 30px;
    padding-left: 35px;
    border-right: 1px solid rgb(83, 83, 83);
    background-color: rgb(0, 0, 0);
    user-select: none;
}
.logo-container {
    display: flex;
    align-items: center;
}

.navbar-logo {
    width: 50px;
    height: 50px;
    margin-right: 11px;
    margin-left: -3px;
}
.logo-title {
    font-weight: bold;
}

.nav-menu-container {
    margin-top: 40px
}

.nav-title-menu {
    font-weight: bold;
    margin-top: 20px;
}

.menu-icon {
    /* width: 20px;
    height: 20px; */
    filter: invert(35%);
    /* margin-right: 15px; */
}
.menu-icon-active {
    /* width: 20px; */
    /* height: 20px; */
    filter: invert(25%) sepia(90%) saturate(5940%) hue-rotate(268deg) brightness(92%) contrast(92%);
    /* margin-right: 15px; */
}

.menu-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
}
.menu-subtitle {
    color: rgb(99, 99, 99);
}
.menu-subtitle-active {
    color: blueviolet;
}
.petite-icon-highlights {
    height: 17px;
    width: 7px;
    background-color: blueviolet;
    position: absolute;
    right: -4px;
    outline: 6px solid black;
    border-radius: 10px;
    opacity: 0;
}
.petite-icon-highlights-active {
    height: 17px;
    width: 7px;
    background-color: blueviolet;
    position: absolute;
    right: -4px;
    outline: 6px solid black;
    border-radius: 10px;
    opacity: 1;
}


.menu-container:hover .menu-icon {
    filter: invert(25%) sepia(90%) saturate(5940%) hue-rotate(268deg) brightness(92%) contrast(92%);
    /* cursor: pointer; */
}
.menu-container:hover .menu-subtitle {
    color: blueviolet;
}
/* .menu-container:hover .petite-icon-highlights {
    opacity: 1;
} */