.movie-cards {
    display: flex;
    flex-direction: column;
    /* margin-top: 18px; */
  }
  
  .movie_item {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  
  .pic-container {
    margin-right: 10px;
  }
  
  .movie-picture {
    width: 120px;
    height: 135px;
    border-radius: 10%;
    object-fit:cover;
  }

  .movie-infos {
    width: 110px;
    height: 135px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .movie-name {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .saisonepisodes {
    font-size: 13px;
    color: gray;
  }
  .score {
    font-size: 14px;
    color: rgb(162, 81, 238);
    margin-right: 6px
    
  }
  .score-container {
    position: relative;
    display: flex;
    border: 1px solid rgb(162, 81, 238);
    align-items: center;
    border-radius: 20px;
    width: 60px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 9px;
    padding-left: 9px;
  }
  .star-color {
    margin-bottom: 1px;
    width: 12px;
    height: 12px;
    filter: invert(40%) sepia(93%) saturate(2180%) hue-rotate(244deg) brightness(92%) contrast(103%);
  }
  .mark {
    position: absolute;
    top: 18%;
    right: -50%;
    width: 22px;
    height: 22px;
  }
  