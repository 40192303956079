.maincontent-container {
    height: 3000px;
    margin-left: 230px;
    margin-right: 295px;
    padding-top: 34px;
    padding-left: 30px;
    padding-right: 30px;
}
.main-top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.main-top-left {
    display: flex;
}
.movie-title {
  font-size: 14px;
  margin-right: 40px;
}
.movie-title-active {
  /* font-size: 14px; */
  margin-right: 40px;
  color: blueviolet;
}
.serials-title {
  /* font-size: 14px; */
  margin-right: 40px;
}
.serials-title-active {
  font-size: 14px;
  margin-right: 40px;
  color: blueviolet;
}
.main-top-right {
    display: flex;
    align-items: center;
}
.profile-pic {
    margin-left: 20px;
    width: 32px;
    height: 32px;
    border-radius: 20px;
}
.notification-pic {
    /* padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 7px;
    padding-left: 7px;
    width: 32px;
    height: 32px;
    border-style: solid;
    border-width: 1px;
    border-radius: 20px; */
    filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(346deg) brightness(100%) contrast(88%);
}

.carousel {
    position: relative;
    width: 100%;
    height: 400px;
    /* background-color: lightblue; */
  }
  
  .carousel-content {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 15px;
    padding-left: 12px;
    width: 100%;
    /* transform: translate(-50%, -50%); */
    /* text-align: center; */
    z-index: 10;
  }
  .overlay {
    /* position: absolute;
    top: 0;
    left: 0; */
    /* background-color: rgb(107, 11, 11); */
    
    /* width: 840px;
    height: 400px; */
    /* opacity: 0.9; */
    /* z-index:0; */
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    
    background: -webkit-linear-gradient(right, rgba(12, 12, 12, 0) 5%,rgba(0, 0, 0, 0.89) 100%); /* Chrome10+,Safari5.1+ */
    
    
  }
  
  .carousel-image {
    /* max-width: 100%;
    max-height: 100%; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 19px;
    
  }
  .previous-btn {
    width: 16px;
    height: 16px;
    filter: invert(100%);
    margin-right: 6px;
  }
  .next-btn {
    width: 16px;
    height: 16px;
    filter: invert(100%);
  }
  .anime-carousel-title {
    font-size: 30px;
    margin-top: 20px;
  }
  .anime-saisons-title {
    color: rgb(156, 156, 156);
    font-size: 14px;
  }
  .anime-resume-carousel {
    margin-top: 30px;
    color: rgb(156, 156, 156);
    font-size: 13px;
    width: 220px;
    height: 190px;
    overflow: hidden;
    overflow-y: scroll;
  }
  .main-score {
    font-size: 14px;
    color: rgb(255, 255, 255);
    margin-right: 6px;
    
  }
  .main-score-container {
    background-color: white ;
    position: absolute;
    display: flex;
    border: 1px solid rgb(162, 81, 238);
    align-items: center;
    border-radius: 20px;
    width: 60px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 9px;
    padding-left: 9px;
    z-index: 11;
  }
  .main-anime-carousel-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: aqua; */
    /* width: 1300px; */
  }
  .test2 {
    display: flex;
    background-color: rgb(162, 81, 238);
    color: white;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 9px;
    padding-left: 9px;
    width: 60px;
    border: 1px solid rgb(162, 81, 238);
    border-radius: 20px;
    margin-right: 20px;
  }
  .main-star-color {
    margin-bottom: 1px;
    width: 12px;
    height: 12px;
    filter: invert(96%) sepia(34%) saturate(5222%) hue-rotate(181deg) brightness(111%) contrast(104%);    
    margin-left: 3px;
  }
  .star-color {
    
    
    filter: invert(96%) sepia(34%) saturate(5222%) hue-rotate(181deg) brightness(111%) contrast(104%);    
    
  }
  
/* -------------------------------------------------- */
