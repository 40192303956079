/* body {
    background-color: rgb(23, 23, 23);
} */
.page-container {
    height: 100vh;
    width: 100%;
    /* display: flex;
    justify-content: center; */
    /* align-items: center; */
    padding-top: 40px;
    padding-left: 15%;
    padding-right: 10%;
}
.page-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 18px;
    /* justify-content:center; */
    align-items: start;
}
.anime-card {
    /* padding: 16px; */
    /* display: flex;
    flex-direction: column; */
    /* border-radius: 24px ;
    border-width: 1px;
    border-color: rgb(64 64 64); */
    /* background-color: rgb(38 38 38); */
    color: white;
    position: relative;
    width: 200px;
    height: 235px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}
.anime-pic-container {
  
}
.anime-pic-landingpage {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 6px;
    margin-bottom: 6px;

}
.anime-card:hover .anime-pic-landingpage {
    filter: brightness(50%);
}

.anime-title {
    
    /* overflow: hidden; */
    /* background-color: lightblue; */
    overflow: hidden;
    text-overflow: ellipsis;
}

.Seen-button {
    position: absolute;
    top: 1px;
    right: -1px;
    height: 40px;
    width: 40px;
    opacity: 0;
    transition: opacity 0.15s;
}

.Seen-button-active {
    position: absolute;
    top: 6px;
    right: 15px;
    height: 40px;
    width: 40px;
}

.anime-card:hover .Seen-button {
    opacity: 1;
}

/* .anime-card .Seen-button:active {
    opacity: 1;
} */

