/* body {
    background-color: rgb(23 23 23);
} */

.dashboard-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.dashboard-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content:center;
    align-items: start;
}
.button-card {
    
    padding: 1rem/* 16px */;
    border-radius: 1.5rem/* 24px */;
    border-width: 1px;
    border-color: rgb(64 64 64);
    background-color: rgb(38 38 38);
    margin-right: 30px;
}
.profile-card {
    padding: 1rem/* 16px */;
    border-radius: 1.5rem/* 24px */;
    border-width: 1px;
    border-color: rgb(64 64 64);
    background-color: rgb(38 38 38);
}

.button-update-profile {
    width: 100%;
    border: solid;
    border-width: 1px;
    border-color: white;
    color: white;
    background-color: rgb(65, 65, 65);
    font-weight: 500;
    border-radius: 1.5rem/* 24px */;
    padding-left: 1.25rem/* 20px */;
    padding-right: 1.25rem/* 20px */;
    padding-top: 0.625rem/* 10px */;
    padding-bottom: 0.625rem/* 10px */;
    text-align: center;
    margin-bottom: 15px;
}

.profile-content {
    
}
