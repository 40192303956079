::-webkit-scrollbar {
  width: 0;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgba(5, 5, 5, 0.521);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(56, 56, 56);
  border-radius: 100vw;
}

@media screen and (max-width: 500px) {
  .navbar {
    display: none;
  }
  .slide-container {
    display: flex;
    flex-wrap: nowrap;
    /* flex-direction: column;
    justify-content: center; */
    /* position: absolute; */

    
    
  }
  .page-content-container {
    /* position: absolute; */
    margin-left: 16px;
    color: white;
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
  }
  .background-anime-picture {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
    visibility: hidden;
    z-index: 0;
  }
  .carousel-container {
    /* background: #f1f1f1; */
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  .carousel-item {
    height: 100%;
    min-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .anime-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 5;
    position: absolute;
    top: 100px;
    /* top: 30%;
    -ms-transform: translateY(-30%);
    transform: translateY(-30%); */
    /* top: 120px; */
    width: 76%;
    height: 40%;
    /* background-color: lightcoral; */
  }
  .anime-name {
    font-size: 31px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 31px;
  }

  .anime-resume {
    margin-bottom: 15px;
    font-size: 13px;
  }

  .anime-button {
    color: white;
    background-color: rgb(19, 19, 19);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
  }
  .anime-button-picture {
    margin-left: 20%;
    padding-top: 4px;
    height: 75%;
    width: 15%;
    filter: invert(100%);
  }
  .anime-bottom {
    /* z-index: 5; */
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items: center;
    /* margin-bottom: 40px; */
    /* background-color: rgb(128, 240, 240); */
    height: 45%;
  }

  .anime-bottom-first-section {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    /* flex-direction: column; */
    /* position: absolute;
    left: 0;
    bottom: 20px; */
    /* width: 200px; */
    /* margin-right: 0px; */
    /* width: 50%; */
    /* background-color: rgb(46, 46, 46); */
  }

  .anime-bottom-second-section {
    /* position: absolute;
    right: 10px;
    bottom: 20px; */
    
    align-items: center;
    display: grid;
    grid-template-columns: 302px 302px 302px;
    /* column-gap: 10px; */
    /* width: 426px; */
    width: 100%;
    height: 170px;
    overflow-x: auto;
  }
  .anime-bottom-picture-container {
    /* background-color: gray; */
    height: 170px;
    margin-right: 10px;
  }
  
  .anime-bottom-picture-1 {
    height: 170px;
    /* width: 302px; */
    object-fit: cover;
    border-radius: 9px;
    width: 100%;
    flex: 1;
  }
  
  .rating-bottom-container {
    display: flex;
  }
  .anime-notation-container {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-right: 7px;
  }
  
  .rating-star-picture {
    height: 13px;
    width: 13px;
    margin-right: 5px;
    margin-bottom: 6px;
  }
  
  .rating-title {
    margin-bottom: 8px;
  }
  
  .anime-author-container {
    display: flex;
    flex-direction: column;
    /* width: 100px; */
    /* flex: 1; */
    width: 25%;
    
    margin-right: 6px;
  }
  
  .author-title {
    margin-bottom: 5px;
  }
  
  .author-name {
    color: gray;
    font-size: 13px;
    margin-right: 6px;
  }
  
  .anime-bottom-genre-container {
    display: flex;
    flex-direction: column;
    /* width: 100px; */
    /* flex: 1; */
    width: 35%;
    margin-right: 6px;
  }
  
  .anime-bottom-genre {
    
  }
  
  .genre-title {
    margin-bottom: 5px;
  }
  
  .anime-genre {
    color: gray;
    font-size: 12px;
  }
}

@media (min-width: 501px) {
  :root {
    --color: #ff5a5e;
    --inactive-color: rgb(211, 211, 211);
    --stroke-width: 2;
  }

  .list {
    display: flex;
    flex-direction: column;
  }

  .list__item {
    margin-bottom: 14px;
  }

  .list__item:last-child {
    margin-right: 0;
  }

  .circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  .circle-back {
    position: absolute;
    inset: 0;
    color: var(--inactive-color);
    stroke: currentColor;
    stroke-width: var(--stroke-width);
    fill: none;
  }

  .circle-front {
    position: absolute;
    inset: 0;
    color: var(--color);
    stroke: currentColor;
    stroke-width: var(--stroke-width);
    fill: none;
    /* Make the start of the animation visible from above. */
    transform: rotate(-90deg);
    /*  */
    stroke-dasharray: 157 157;
    stroke-dashoffset: -157;
    transition: stroke-dashoffset ease-in-out 500ms;
  }

  .circle.active .circle-front {
    /* Animate with keyframes instead of transition. */
    transition: none;
    /* Indication of 0% progress. */
    stroke-dasharray: 0 157;
    stroke-dashoffset: 0;
    animation: spin 4s linear;
    animation-fill-mode: forwards;
  }

  .dot {
    display: block;
    width: 8px;
    height: 8px;
    background: var(--inactive-color);
    border-radius: 8px;
  }

  .circle.active .dot {
    background: var(--color);
  }

  @keyframes spin {
    from {
      stroke-dasharray: 0 157;
    }

    to {
      stroke-dasharray: 157 157;
    }
  }

  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    border: 1px solid rgba(145, 145, 145, 0.623);
    border-left: none;
    border-top: none;
    border-bottom: none;
  }
  .page-content-container {
    position: absolute;
    margin-left: 300px;
    color: white;
  }
  .background-anime-picture {
    height: 100vh;
    width: 100%;
  }

  .carousel-container {
    /* background: #f1f1f1; */
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  .carousel-item {
    height: 100%;
    min-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .anime-infos {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 400px;
  }

  .anime-name {
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 40px;
  }

  .anime-resume {
    margin-bottom: 30px;
  }

  .anime-button {
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid gray;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .anime-button-picture {
    margin-left: 20px;
    padding-top: 4px;
    height: 25px;
    width: 25px;
    filter: invert(100%);
  }
  
  .anime-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    margin-bottom: 50px;
    /* background-color: lightcoral; */
  }

  .anime-bottom-first-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 100px;
    width: 50%;
    /* background-color: rgb(46, 46, 46); */
  }

  .anime-bottom-second-section {
    align-items: center;
    display: grid;
    grid-template-columns: 302px 302px 220px;
    column-gap: 20px;
    width: 50%;
    overflow-x: auto;
  }
  .anime-bottom-picture-container {
    /* background-color: gray; */
    height: 170px;
  }
  
  .anime-bottom-picture-1 {
    height: 170px;
    object-fit: cover;
    border-radius: 6px;
    width: 100%;
  }
  
  .rating-bottom-container {
    display: flex;
  }
  
  .rating-star-picture {
    height: 17px;
    width: 17px;
    margin-right: 6px;
  }
  
  .rating-title {
    margin-bottom: 8px;
  }
  
  .anime-author-container {
  }
  
  .author-title {
    margin-bottom: 8px;
  }
  
  .author-name {
    color: gray;
  }
  
  .anime-bottom-genre-container {

  }
  
  .anime-bottom-genre {
    display: flex;
  }
  
  .genre-title {
    margin-bottom: 8px;
  }
  
  .anime-genre {
    color: gray;
    margin-right: 6px;
  }
}

/* bg-cover bg-center h-screen w-screen z-0
bg-cover bg-center h-screen w-screen z-0
bg-cover bg-center h-screen w-screen z-0
bg-cover bg-center h-screen w-screen z-0
bg-cover bg-center h-screen w-screen z-0 */



/* 
@media (max-height: 750px) or (max-width: 1300px) {
  .anime-infos {
    position: absolute;
    top: 40%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 400px;
  }
  .anime-name {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .anime-bottom-first-section {
    display: inline;
    margin-right: 100px;
    width: 50%;
  }
  .rating-bottom-container {
    display: flex;
    margin-bottom: 10px;
  }
  .anime-resume {
    margin-bottom: 15px;
  }
} */

